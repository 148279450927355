<template>
    <v-container>
        <ASuccessFour :api="api"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="">
            <v-col
                v-if="api.isLoading"
                cols="12"
                sm="12"
                class="mx-auto pa-0"
                >
                <v-skeleton-loader
                    type="table"
                    ref="skeleton">
                    
                </v-skeleton-loader>

            </v-col>
            <v-col v-else align="center" justify="center" cols="12" sm="12" class="mx-auto pa-0">
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Generate certificate
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            no-gutters>
                            <v-col
                                cols="12"
                                sm="12">
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <div>
                                                <v-text-field
                                                    dense 
                                                    outlined
                                                    label="Name"
                                                    v-model="attendance.name">
                                                </v-text-field>
                                            </div>

                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-text-field
                                        dense 
                                        outlined
                                        v-model="attendance.duration"
                                        label="Duration ( minutes )"
                                        :hint="convertToHours"
                                        type="number">
                                    </v-text-field>
                                </v-container>
                                <v-container>
                                    <div
                                        class="d-flex justify-end">
                                        <v-btn
                                            @click="$emit('cancel')"
                                            color="primary"
                                            plain>
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            @click="validateInput"
                                            color="primary">
                                            Confirm

                                        </v-btn>
                                    </div>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>

                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
        convertToHours() {
            return Math.floor(this.attendance.duration/60) +' hour(s) '+Math.floor(this.attendance.duration%60)+' minutes(s)';
        }
    //
    }),
    props:[
        'attendance','meeting_id'
    ],
    data: () => ({
        data:{
            fname:null,
            lname:null,
            email:null,
            meeting_title:null,
            duration:null,
        },
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class==="pdfDownloadWebinar") {
                this.openNewTabPdf(resp.data);
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    },
    methods: {
        fetch(){
            // write function here
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createApi = this.create();
            this.$api.fetch(createApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/meetings/webinars/certificate/download/"+this.meeting_id+"?email="+this.attendance.user_email+"&name="+this.attendance.name+"&duration="+this.attendance.duration+"&download_type=customize";
            return tempApi;
        },
        openNewTabPdf(data) {
            let link = document.createElement("a");
            link.href = data;
            link.target="_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>